
export default {
    common: {
        done: "Done",
        record: "Record",
        show: "Show",
        activate: "Activate",
        evaluate: "Evaluate",
        add: "Add",
        create: "Create",
        detail: "Detail",
        admin: "Administrator",
        manager: "Manager",
        save: "Save",
        month: "Month",
        day: "Day",
        user: "User",
        week: "Week",
        yes: "Yes",
        no: "No",
        active: "Active",
        inactive: "Inactive",
        edit: "Edit",
        close: "Close",
        clear: "Clear",
        delete: "Delete",
        search: "Search...",
        change: "Change",
        cancel: "Cancel",
        noRecords: "No records were found.",
        deleteItemMessage: "Are you sure you want to delete this item?",
        isOk: "OK",
        isOkWithWarning: "OK with objections",
        isOkWithWarningShort: "OK with obj.",
        isNotOk: "NOK",
        waiting: "Waiting",
        created: "Created",
        notCreated: "Not created",
        none: "None",
        hasNone: "Without disagreements",
        closed: "Closed",
        inProgress: "In progress",
        notClosed: "Not closed",
        all: "All",
        onlyMine: "Only mine",
        withNok: "With disagreements",
        withoutNok: "Without disagreements",
        clickAndSelect: "Click here and select",
        uploadFile: "Upload file (max. {maxUploadFileSize} MB)",
        finishUntil: "Meet by",
        fileTooBig: "The file is too large",
        score: "Score",
        systemAudit: "SystemAudit",
        scoreShort: "Sc.",
        requiredEscalation: "Required findings",
        optionalEscalation: "Optional findings",
        noEscalation: "No findings",
        name: "Name",
        copy: "Copy to clipboard",
        copySuccess: "Successfully copied to clipboard",
        useDefinedExplanation: "Set explanatory notes for all audits of this category. You can change these explanations for a specific audit in the audit settings.",
        useCommentOnResponse: "Allow comments on responses",
        useNotRatedOption: "Allow the option not to rate the question",
        enforceReasonForNotRatedOption: "Require the entry of a reason for not rating when choosing not rated",
        useMeasureType: "Use types of measures (corrective/preventive)",
        enforceInstuctionsConfirmation: "Require read confirmation of audit entry",
        explanation: "Explanation",
        dateSince: "Date since",
        dateTill: "Date till",
        rowsPerPage: "Rows per page",
        workplace: "Workplace",
        shiftType: "Shift",
        notRated: "Not rated",
        unsavedChanges: "Unsaved changes",
        unsavedChangesText: "We have registered unsaved changes. Do you want to save them before closing?",
        thisFileIsRequired: "This file is required.",
        localeProp: "en-EN",
        downloadTemplate: "Download template",
        showError: "Show error",
        showWarning: "Show warning",
        enterAnInteger: "Enter an integer.",
        mustBeMoreOrEqual: "Value must be more or equal {value}.",
        mustBeLessOrEqual: "Value must be less or equal {value}.",
        cannotBeUndone: "This action cannot be undone.",
        yesDelete: "Yes, remove",
        downloadFile: "Download file",
        correctMistakes: "Correct mistakes"
    },
    days: {
        mo: "Mon",
        tu: "Tue",
        we: "Wed",
        th: "Thu",
        fr: "Fri",
        sa: "Sat",
        su: "Sun"
    },
    menu: {
        dashboard: 'Dashboard',
        audit: 'Audit templates',
        tasks: "Task calendar",
        responses: "Performed audits",
        unfinishedResponses: "Unfinished audits",
        settings: "Settings",
        issues: "Findings",
        issuesLong: "Findings (personal TO-DO)",
        solvedIssues: "Measures",
        solvedIssuesLong: "Measures (TO-DO)",
        verification: "Checking the effectiveness of measures",
        verificationLong: "Checking the effectiveness of measures (personal TO-DO)",
        reports: "Reports",
        review: "Audit approval",
        reviewLong: "Audit approval (personal TO-DO)"
    },
    audits: {
        name: "Audit name",
        categoryName: "Audit category",
        addAudit: "Add audit",
        cloneAudit: "Clone audit",
        owner: "Audit owner",
        performAudit: "Perform audit",
        done: "Done",
        unfinished: "Unfinished",
        auditDetail: "Audit detail",
        auditClose: "Closing the audit",
        auditDefinition: "Audit description",
        aiReportInstructions: "Additional entry for AI when generating the audit report",
        auditLateChangesQuestion: "Want to make changes or insert photos/files later?",
        wantToMakeChangesLater: "I want to make changes later",
        wantToFinishAndLock: "Finish and lock",
        okAccept: "OK, accepted",
        someItemsWereNotTyped: "Some items were not filled in.",
        auditExists: "Audit number already exists.",
    },
    audit: {
        menu: {
            general: "General",
            questions: "Questions",
            plan: "Plan",
            calendar: "Calendar",
            responses: "Responses",
            reports: "Reports"
        },
        general: {
            name: "Audit name",
            deleteAudit: "Remove audit",
            deleteAuditMessage: "Are you sure you want to remove the audit?",
            auditState: "Audit state",
            explanationType: "How to use explanatory notes",
            explanationTypeNone: "Do not use explanations",
            explanationTypeByCategory: "According to the category settings",
            explanationTypeByAudit: "Set uniform for the entire audit",
            explanationTypeByQuestionGroup: "Set for groups of questions",
            procestElements: "Process elements",
            procestElementCount: "Process element count",
            label: "Name",
            stepNumber: "Step number",
            useProductGroups: "Use product groups",
            productGroups: "Product groups",
            productGroupName: "Product group name",
            addProductGroup: "Add product group"
        },
        questionGroups: {
            name: "Question group name",
            add: "Add group",
            processElement: "Process element",
            processSubElement: "Process sub-element"
        },
        questions: {
            code: "Question code",
            name: "Question",
            list: "List of questions",
            add: "Add question",
            description: "Question details",
            disagreementResponse: "Disagreement response",
            explanations: "Explanations",
            uniformExplanationsCheckbox: "Explanations for all groups of questions uniform",
            explanatoryPictures: "Explanatory pictures and files",
            noImages: "No images uploaded",
            deleteImage: "Delete image",
            deleteImageMessage: "Are you sure you want to delete this file?",
            questionFileTip: "Tip: Drag&Drop an item to another location to adjust the order",
            isHighRisk: "Question involving special risks"
        },
        plan: {
            repeatEvery: "Repeat every",
            nextBusinessDayOnHoliday: "In the case of a holiday, plan for the next working day",
            add: "Add",
            dayOfMonth: "Day of month",
            occurrenceCount: "The number of repetitions",
            planFromDate: "Plan from",
            planToDate: "Plan until",
            userGroup: "User group",
            days: "Days",
            interval: "Period",
            daysOfWeek: "Days in week",
            typeOfPlanning: "Type of planning",
            workplace: "Workplace",
            shiftType: "Shift"
        },
        recapitulation: {
            header: "Recapitulation",
            workplaceNameMissing: "The workplace name is missing",
            unansweredQuestionsCount: "Number of unanswered questions",
            auditorCommentMissing: "Auditor's comment is missing",
            immediateMeasuresDescriptionMissing: "A description of immediate measures is missing",
            auditorEscalationCommentsMissing: "Auditor comments are missing for escalation",
            missingImmediateMeasures: "A description of immediate measures is missing",
            missingEscalationDescription: "A description of the findings is missing"
        }
    },
    planItems: {
        evidenceNumber: "Audit number",
        category: {
            name: "Category name",
        },
        audit: {
            name: "Audit name",
            number: "Audit number"
        },
        user: {
            displayName: "Auditor",
            department: "Auditor department",
            position: "Auditor position"
        },
        implementerUser: {
            displayName: "Implementer of measures"
        },
        managerUser: {
            displayName: "Administrator of measures"
        },
        findingManagers: "Administrators of measures",
        workplace: {
            name: "Workplace"
        },
        shiftType: {
            name: "Shift"
        },
        responseCount: "Response count",
        okCount: "OK count",
        okCountWithWarning: "OK with objections count",
        nokCount: "NOK count",
        successRate: "Overall score in %",
        scoreValue: "Total score",
        notAnswered: "Not answered",
        time: "Date of audit",
        finishTime: "Date of audit",
        correctionState: "Measures",
        implementationState: "State of measures",
        planItemState: "The state of the performed audit",
        response: "Response",
        userGroup: "User group",
        state: {
            WaitingToPerform: "Waiting to perform",
            ClosedWithoutFindings: "Closed, no findings",
            NeedsToCreateMeasures: "Needs to create measures",
            NeedsToPerformMeasures: "Needs to perform measures",
            NeedsToVerifyMeasures: "Needs to verify measures",
            AllMeasuresVerified: "All measures performed and verified",
            PerformedWithoutFindings: "Audit performed without findings",
            WaitingForApproval: "Waiting for approval",
            Approved: "Audit approved",
            Declined: "Audit not approved",
            Closed: "Closed"
        }
    },
    workTask: {
        downloadAudit: "Download the audit",
        download: "Download",
        sendToApprove: "Submit for approval",
        sendAgainToApprove: "Resubmit for approval",
        declineAuditApproval: "Reject audit approval",
        audit: "Audit",
        finding: "Findings",
        correction: "Measures",
        verify: "Effectiveness of measures",
        issueDescription: "Issue description",
        escalate: "Add findings",
        cancelEscalation: "Remove findings",
        immediateMeasures: "Description of immediate problem fix",
        addComment: "Add comment",
        cancelComment: "Remove comment",
        addImmediateMeasures: "Add immediate problem fix",
        cancelImmediateMeasures: "Remove immediate problem fix",
        implementationControllers: "Verifiers of the effectiveness of the implementation of measures",
        reworkComment: "Comment to re-implementation",
        filesToControl: "Files to verification of the effectiveness of the implementation",
        sendToControl: "Send to user to verify of the effectiveness of the implementation",
        willBeSendToControl: "After saving, it will be sent to verify of the effectiveness of measure implementation",
        myControl: "My tasks of checking the effectiveness of measures",
        othersControl: "Tasks for checking the effectiveness of other users' measures",
        noCorrectionToControl: "No measures are available to control the effectiveness of implementation.",
        enterControl: "Enter the results of the control of the effectiveness of the measure",
        editControl: "Edit the results of the measure effectiveness check",
        doneEffectively: "The measure is effective",
        takeBackToImplementer: "Return to implementer",
        sendToImplementer: "Send to implementer",
        willBeTakeBackToImplementer: "It will be returned to the implementer of the measure.",
        mineMeasures: "My measures to implement",
        noMineMeasures: "There are no implementation measures available.",
        otherMeasures: "Measures to be implemented by other users",
        noFindings: "No findings available.",
        findigsForOthers: "Auditor's findings for other administrators",
        approveDialog: {
            auditApproval: "Audit approval",
            approveAudit: "Approve the audit",
            auditWillBeApproved: "The audit will be approved.",
            auditWillBeSend: "The audit will be sent for approval.",
            auditSendToApprove: "Submitting an audit for approval",
            approver: "Approver"
        },
        closeDialog: {
            closeAudit: "Close the audit",
            close: "Close",
            auditWillBeClosed: "The audit will be closed.",
            planControlAudit: "Schedule a follow-up audit"
        },
        createDialog: {
            planAudit: "Schedule an audit",
            periodTitle: "Schedule for",
            specificDay: "Specific day",
            period: "Period"
        },
        declineDialog: {
            auditDecline: "Audit rejection",
            declineAuditApproval: "Disapprove audit",
            auditWontBeApproved: "The audit will not be approved and will be returned for revision.",
            enterReason: "Enter the reason for disapproving the audit"
        },
        findingItem: {
            correctionManagers: "Administrators of measures",
            correction: "Measure",
            addCorrection: "Add measure",
            deleteCorrection: "Remove measure",
            finding: "Cause",
            findingAndCorrection: "Cause and measures",
            finishDate: "Deadline for completion",
            realizationDate: "Deadline for implementation",
            newRealizationDate: "New deadline for implementation",
            correctionType: "Measure type",
            correctionFiles: "Files attached to measure",
            correctiveMeasures: "Corrective measure",
            preventiveMeasures: "Preventive measure",
            enterFindingAndCorrection: "Enter the cause and measure",
            editFindingAndCorrection: "Edit the cause and measure",
            corrective: "Corrective",
            preventive: "Preventive"
        },
        verifyMeasureItem: {
            question: "Question",
            answer: "Answer"
        },
        audits: {
            auditor: "Auditor",
            department: "Department",
            position: "Position",
            finishTime: "Date of audit",
            approver: "Approver",
            requestReviewOn: "Date sent for approval",
            reviewedOn: "Audit approval date",
            declinedOn: "Audit disapproval date",
            approvedBy: "Approved by user",
            declinedBy: "Disapproved by user",
            reasonForDecline: "Reason for disapproval",
            showAuditDescription: "View audit assignment",
            auditDescription: "Audit assignment",
            delete: "Delete",
            escalateToManager: "Escalate to an administrator",
            allManagers: "All administrators",
            selectedManagers: "Selected administrators",
            notRatedReason: "Reason for not rating"
        },
        processStep: "Process step",
        deleteWorkTask: "Remove the performed audit",
        deleteWorkTaskMessage: "Do you really want to remove performed audit {name}?",
        report: "Audit report"
    },
    workTasks: {
        showTable: "Table",
        showKanban: "Columns",
        iCalInfo: "Click to bring up a dialog with details for calendar subscription to Outlook, Google or iCloud calendar.",
        iCalUrl: "URL to subscribe the calendar to the Outlook, Google or iCloud calendar",
        iCalP1: "The subscription will only be made for your tasks, not the tasks of other users. Keep the URL link secret and do not share it with other people.",
        iCalP2: "Copy and paste the calendar URL into your Outlook, Google or iCloud calendar. Set up a subscription to this calendar in Outlook, Google or iCloud calendar."
    },
    unanswered: {
        noCategory: "The user group is not set in the audit plan",
        noAuditTiming: "None, scheduled one time"
    },
    issues: {
        performanceFromDate: "Perform audit from date",
        performanceToDate: "Perform audit until",
        time: "Date of audit",
        audit: {
            name: "Audit name",
            number: "Audit number"
        },
        user: {
            displayName: "Auditor"
        },
        question: {
            code: "Question code"
        },
        state: "State of corrective measures",
        stateOfMeasures: "State of measures",
        stateOfMeasure: "State of measure",
        repaired: "Repaired",
        needsRepair: "Needs repair",
        response: "Comment",
        responseText: "Do not enter a description of the findings in the comment",
        escalationDescription: "Auditor's findings",
        findingsFiles: "Files attached to finding",
        responseFiles: "Files attached to response",
        repairment: "Corrective measure",
        measure: "Measure", 
        implementer: "Implementer of measures",
        implementerOfMeasure: "Implementer of measure",
        implementationState: "Implementation status of measures",
        implementationStateSingle: "State of measure",
        implemented: "All measures implemented",
        needsImplementation: "Needs implementation of measure",
        disagreements: "Deviations",
        repairmentComment: "Comment on measure",
        repairmentDescription: "Measures description",
        measureDescription: "Measure description",
        implementUntil: "Implement until",
        implementationTime: "Implementation date",
        implementationDelay: "Implementation delay (days)",
        implementationComment: "Implementation comment",
        implementationFiles: "Files attached to implement measure",
        editImplementation: "Edit the implemented measure",
        enterImplementation: "Enter the implementation of measure"
    },
    issueDialog: {
        new: "New",
        inProgress: "In progress",
        verified: "Verified",
        waiting: "Waiting",
        performed: "Performed",
        waitingForVerification: "Waiting for verification"
    },
    measures: {
        New: "New",
        InProgress: "In progress",
        WaitingForVerification: "Waiting for verification",
        Verified: "Verified",
        unfinished: "Unfinished",
        finished: "Finished"
    },
    settings: {
        menu: {
            users: "Users",
            catalog: "Catalogue",
            organization: "Company",
            workplaces: "Workplaces",
            categories: "Audit categories",
            roles: "Roles",
            groups: "User groups",
            notRatedOptionReasons: "Reasons for not rating",
            departments: "Departments",
            positions: "Positions",
            shiftTypes: "Shifts",
            import: "Import",
        },
        accounts: {
            inviteToOrganization: "Invite to company",
            invite: "Invite",
            deleteInvitation: "Delete invitation",
            deleteInvitationConfimMessage: "Are you sure you want to delete user invitation {email}?",
            deleteUser: "Delete user",
            deleteUserConfirmMessage: "Are you sure you want to delete user {email}?",
            invitations: "Invitations",
            columnName: "Name",
            columnPhoneNumber: "Phone number",
            columnEmail: "E-mail",
            columnRoles: "Roles",
            avatar: "Profile photo",
            addRole: "Add role",
            maxUserCountReached: "The maximum number of active users has been reached.",
            allowedCategories: "Allowed categories of audits",
            addAllowedCategory: "Add allowed category",
            all: "All",
            selected: "Selected",
            activeUsers: "Active users",
            inactiveUsers: "Inactive users",
            company: "Company",
            registeredUsersList: "List of registered users",
            allCategories: "All categories",
            department: "Department",
            position: "Position",
            activeUserCount: "Active user count",
            inactiveUserCount: "Inactive user count"
        },
        organization: {
            name: "Company name",
            street: "Street",
            city: "City",
            zip: "ZIP",
            identificationNumber: "Identification number",
            vat: "VAT",
            repeatHeader: "Repeat settings",
            repeatEvery: "Repeat every",
            licenceToDate: "License valid until",
            licenceUserCount: "Number of active users in the license",
            isEnabledCommentOnOkResponse: "Allow comments and file attachments on OK responses",
            organizationIcon: "Company icon",
            upload: "Upload",
            registeredOrganizationsList: "List of registered companies",
            detail: "Company detail",
            twoFactor: "Two-factor login",
            isRequiredTwoFactor: "Require two-factor login",
            isRequiredTwoFactorDescription: "By activating this option, you force users to set mandatory two-factor login. Without two-factor login, users will not have access to the data of this company within the scope of their authorizations.",
            imageUploadOptimization: "Optimizing photos when uploading to audit",
            imageUploadUseCompression: "Use photo optimization",
            imageUploadMaxResolution: "Maximum resolution (width or height)",
            imageUploadQuality: "Reducing the quality of the photo on",
            imageUploadMaxFileSize: "Maximum file size"
        },
        groups: {
            infoText: "User groups are used to schedule performed audits in the calendar of individual users."
        },
        categories: {
            infoText: "Corrective action administrators will receive e-mail notifications of non-conformities from performed audits.",
            responseValueType: "Type of audit responses",
            categorySettings: "Category settings",
            addCategory: "Add category",
            useAuditApproval: "Enable the audit approval process for audits performed with findings",
            useAuditApprovalWithNoFindings: "Enable the audit approval process for audits performed without findings",
            planItemCloseType: "Who closes the audit",
            byAuditor: "Auditor",
            byReviewer: "Approver",
            byAuditorOrReviewer: "Auditor or Approver",
            measureVerificationUserType: "Who should be entrusted with checking the effectiveness of measures",
            auditPerformer: "The auditor who performed the audit",
            userSelect: "Allow the implementer to select from a list of available auditors",
            tenantUsersOfCategory: "List of users for this category"
        },
        import: {
            newImport: "New import",
            auditsAction: "Start import of audit templates",
            audits: "Audit templates",
            templates: "Templates",
            templateName: "Template name",
            previousImports: "Previous imports",
            table: {
                templateHeaders: {
                    name: "Template name",
                    importType: "Import type"
                },
                previousImportsHeaders: {
                    name: "File name",
                    start: "Import start",
                    importType: "Import type",
                    state: "State"
                }
            },
            import: "Import",
            showPreview: "Show preview",
            selectFromPreviousTemplate: "Select from previous template",
            orSetNewImport: "or set new import",
            itemsInTable: "Items in table",
            firstRowContainsHeaders: "First row contains column names",
            stopIfError: "If it is not possible to insert any record - do not continue",
            insertOnlyNewRecords: "Insert only new records (existing ones will not be updated)",
            selectColumnName: "Select the column name in the data file",
            primaryKey: "Primary key",
            mandatoryItems: "Mandatory items",
            importStart: "Start import",
            importOfFile: "Import of file",
            canSaveAs: "You can save your import as template:",
            importHasBeenStarted: "Import has been started",
            saveTemplate: "Save template",
            importError: "Import error",
            started: "Started",
            completed: "Completed",
            completedWithWarnings: "Completed with warnings",
            errorDetail: "Error detail",
            warningDetail: "Warning detail",
            keepStructureInfo: "The imported excel file must have the same structure as the import template."
        }
    },
    categories: {
        name: "Category name",
        correctionManagers: "Administrators of measures",
        deleteCageory: "Delete category",
        deleteCategoryMessage: "Are you sure you want to delete category?",
        cantDeleteCategory: "The category is used in one or more audits, it cannot be deleted."
    },
    authentification: {
        signIn: "Sign in",
        signUp: "Sign up",
        registration: "Sign up",
        passwordRecovery: "Password recovery",
        createAccount: "Create account",
        firstName: "First name",
        lastName: "Last name",
        email: "Email",
        phoneNumber: "Phone number",
        registeredEmail: "Registered email",
        password: "Password",
        passwordAgain: "Password again",
        newPassword: "New password",
        newPasswordAgain: "New password again",
        weWillSendYouRecoveryEmail: "We will send you a password reset link to the registration email.",
        requestPasswordReset: "Request password reset",
        changePassword: "Change password",
        goToSignIn: "Go to Sign in",
        toast: {
            passwordRecovered: "Password recovered successfully",
            passwordRecoveredText: "Password recovered successfully, you can log in now.",
            passwordNotRecovered: "Failed to reset password",
            passwordNotRecoveredText: "An error occurred while resetting the password. Please check the token and try again.",
            userNotFound: "User not found",
            userNotFoundText: "The user with the specified email was not found. Please try again.",
            passwordRecoveryRequestSent: "Password reset request processed successfully",
            passwordRecoveryRequestSentText: "A recovery confirmation link has been sent to your email address."
        },
        registrationWasSuccessful: "Registration has been completed. An activation e-mail has been sent to your e-mail address.",
        activationLinkVerified: "Activation link verified successfully. You can log in now.",
        verifyingActivationLink: "Verifying activation link ...",
        activationLinkFailed: "Activation link failed to verify.",
        enteredPasswordsMustMatch: "Entered passwords must match.",
        invalidLength: "The password must be at least 6 characters long.",
        errors: {
            signInFailed: "Couldn't log in. Please check your login details.",
            duplicateUserName: "An account with this email address already exists.",
            signUpError: "An error occurred while sign up."
        },
        organizationSignUp: {
            create: "Company registration",
            name: "Name of the company",
            identificationNumber: "Identification number",
            vat: "VAT",
            street: "Street",
            city: "City",
            zip: "Zip",
            submit: "Create company"
        }
    },
    groups: {
        name: "Group name",
        groupUsers: "Users",
        deleteGroup: "Delete group",
        deleteGroupMessage: "Are you sure you want to delete group?",
        cantDeleteGroup: "The group is used by one or more users, it cannot be deleted."
    },
    account: {
        header: "User settings",
        firstname: "Firstname",
        lastName: "Lastname",
        phoneNumber: "Phone number",
        twoFactor: "Two-step verification (Google authenticator)",
        email: "E-mail",
        profilePhoto: "Profile image",
        upload: "Upload",
        passwordChange: "Password change",
        oldPassword: "Old password",
        password: "New password",
        passwordAgain: "New password again",
        connectedOrganizations: "Connected companies",
        disconnectFromOrganization: "Disconnect from company",
        disconnect: "Disconnect",
        invitations: "Invitations to company",
        invitationsLabel: "You have the following invitations to join these companies",
        connectToOrganization: "Join the company",
        deleteInvitation: "Delete invitation",
        deleteOrganization: "Disconnect from company",
        deleteOrganizationMessage: "Do you really want to disconnect from the company {name}?",
        passwordsHasToMatch: "New passwords must match.",
        checkPassword: "The new password could not be saved, please check the passwords.",
        passwordSaved: "The new password has been saved.",
        avatar: "Profile picture",
        createOrganization: "Create company",
        notifyWeeklyAudits: "Weekly notification to auditors",
        notifyIdleMeasures: "Notification of measures to be implemented",
        notifyCorrectionManagerUpdate: "Notification to corrective action administrators when a nonconformity is entered",
        notifyCorrectionImplementerUpdate: "Notification to implementers of corrective measures after their creation",
        selectLanguage: "Select language",
        calendarUrl: "URL to subscribe the calendar to the Outlook, Google or iCloud",
        urlInfo: "Copy the calendar URL and paste it into your Outlook, Google or iCloud calendar. Set up a subscription to this calendar in your calendar.",
        tenantDialog: {
            header: "Account is not connected to any company",
            p1: "If you have an invitation to join a business account from another user, you can join that business. If you don't have an invitation, ask your colleagues to send one.",
            p2: "Or you can create a new company by clicking the Create Company button. A company account will be created with default settings, which you can adjust in Settings. You can invite other users to this company account in Settings in the Users tab."
        }
    },
    workplaces: {
        code: "Workplace code",
        name: "Workplace name",
        deleteWorkplace: "Remove workplace",
        deleteWorkplaceMessage: "Are you sure you want to remove Workplace?"
    },
    notRatedOptionReason: {
        name: "Name",
        deleteNotRatedOptionReason: "Remove reason",
        deleteNotRatedOptionReasonMessage: "Are you sure you want to remove reason?"
    },
    department: {
        name: "Name",
        deleteDepartment: "Remove department",
        deleteDepartmentMessage: "Are you sure you want to remove department?"
    },
    position: {
        name: "Name",
        deletePosition: "Remove position",
        deletePositionMessage: "Are you sure you want to remove position?"
    },
    roles: {
        name: "Role name",
        permissions: "Permissions",
        deleteRole: "Delete role",
        deleteRoleMessage: "Are you sure you want to delete the role?",
        cantDeleteRole: "The role is used by one or more users, it cannot be deleted."
    },
    permissions: {
        Admin: "Settings (editing company, roles and users)",
        AdminGroups: "Settings (editing user groups, audit categories, workplaces and shifts)",
        Audits: "List of audits and their settings (display)",
        Plan: "View the calendar of all audits",
        EditAllAudits: "Edit settings of all audits",
        EditOwnedAudit: "Create and edit an audit",
        PerformAudit: "Perform audit (Audit application)",
        Response: "Performed audits (display only, incl. corrective measures)",
        Findings: "Reported findings (with editing of measures)",
        Measures: "Measures (with editing, only mine)",
        MeasuresAllUsers: "Measures (with editing, all users)",
        MistakeCorrection: "Correction of mistakes in audit responses",
        MeasureVerification: "Verification of the effectiveness of measure implementation",
        PlanItemReview: "Performed audit approval"
    },
    dashboard: {
        donePlanItemsCount: "Number of completed audits in the last year",
        waitingPlanItemsCount: "Number of waiting audits in the last year",
        donePlanItems: "My completed audits in the last year",
        notFinished: "My today's and ongoing audits",
        waitingPlanItems: "My waiting audits in the last year"
    },
    validation: {
        thisFieldIsRequired: "Required item",
        emailIsRequired: "E-mail is required"
    },
    reports: {
        reportByQuestion: "By questions",
        reportByQuestionTitle: "Chart of the number of deviations on individual issues",
        reportAuditByWorkplace: "By workplace",
        reportAuditByWorkplaceTitle: "Graph of responses by workplace",
        reportAuditByUser: "By auditor",
        reportAuditByUserTitle: "Graph of responses by auditor",
        reportAuditByYear: "By year",
        reportAuditByYearTitle: "Graph of responses by year",
        reportAuditByCategory: "By audit category",
        reportAuditByCategoryTitle: "Graph of responses by audit category",
        question: "Question",
        count: "Count",
        countOk: "Count OK",
        countOkW: "Count OK w/ obj.",
        countNok: "Count NOK",
        percent: "Percent",
        percentOk: "Percent OK",
        percentNok: "Percent NOK",
        totalAuditCount: "Total audit count",
        okAuditCount: "OK count",
        nokAuditCount: "NOK count",
        nokAuditPercent: "Audit failure percentage",
        filters: {
            category: "Audit category",
            fromDate: "Since",
            toDate: "Until",
            user: "Auditor",
            audit: "Audit",
            userGroup: "User group"
        },
        export: "Export to Excel"
    },
    badLicence: {
        licenceExpired: "Your license expired on",
        contactIDomino: "Please contact iDomino.",
        accountNotActive: "Your account is not active with this organization. Please contact your organization administrator."
    },
    responseValue: {
        ok: "OK",
        okWithWarning: "OK with objections",
        notOk: "NOK",
        scoreZero: "Score 0",
        scoreOne: "Score 1",
        scoreTwo: "Score 2",
        scoreThree: "Score 3",
        scoreFour: "Score 4",
        scoreFive: "Score 5",
        scoreSix: "Score 6",
        scoreEight: "Score 8",
        scoreTen: "Score 10",
        notRated: "Not rated",
        showScore: "Show score",
        customizeInfo: "Customize information",
        hideScore: "Hide score",
        totalScore: "Degree of fulfillment"
    },
    mfa: {
        activate: "Activate",
        activateButton: "Activate two-factor authentication",
        deactivate: "Deactivate",
        deactivateButton: "Deactivate two-factor authentication",
        mfaDialogHeader: "Two-factor login",
        mfaActivationInstructionHeader: "Instructions for installing Google Authenticator on a mobile phone:",
        mfaActivationInstructionLine1: "Install the Google Authenticator app on your mobile phone.",
        mfaActivationInstructionLine2: "Click on the + button in the Google Authenticator app.",
        mfaActivationInstructionLine3: "Scan the displayed QR code.",
        mfaActivationInstructionLine4: "Enter the code from Google Authenticator every time you sign in to AMS.",
        mfaActivationInstructionQR: "QR code to activate two-factor login:",
        mfaDeactivationInstructionHeader: "Disable two-factor authentication",
        mfaDeactivationInstructionText: "By continuing, you will disable two-factor login. This reduces the security of your account. If the company connected to your account requires mandatory two-factor login, you will not have access to this company's data until it is reactivated.",
        singInCommand: "Code from Google Authenticator",
        singInCommandError: "Invalid code for two-factor login.",
        noAccess: "Two-factor login is required to access this organization's data. You enable this login in your account management.",
        goToAccount: "Go to account settings"
    },
    files: {
        dropFileHere: "Drop file here (up to 50 MB) from your computer.",
        selectFile: "Select file",
        uploadFile: "Upload file"
    },
    shiftTypes: {
        name: "Shift name",
        deleteShiftType: "Remove shift",
        deleteShiftTypeMessage: "Are you sure you want to remove Shift?"
    },
    systemAudit: {
        Compliant: "Compliant",
        MajorNotCompliant: "Major noncoformance",
        MinorNotCompliant: "Minor noncoformance",
        OportunityForImprovement: "Opportunity for improvement",
        PositiveAspect: "Positive aspect",
        short: {
            Compliant: "CONF",
            MajorNotCompliant: "MA",
            MinorNotCompliant: "mi",
            OportunityForImprovement: "OFI",
            PositiveAspect: "PA",
        }
    }
}
